import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react"

import Seo from "../components/seo"

const IndexPage = () => (
  <>
    <Seo title="Etusivu" />
    <Box>
      <Grid bgColor="blue.700" height="100vh">
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            maxHeight: 2160,
          }}
          layout="fullWidth"
          // You can optionally force an aspect ratio for the generated image
          // aspectRatio={3 / 1}
          alt=""
          src="../images/forest.jpg"
          formats={["auto", "webp", "avif"]}
          placeholder="blurred"
        />
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
          }}
        >
          {/* Any content here will be centered in the component */}
          <Flex
            height="100vh"
            color="white"
            alignItems="center"
            justifyContent="center"
            padding={10}
          >
            <VStack spacing={10}>
              <Heading as="h1" align="center" maxWidth={600}>
                Thermo Care Oy on lopettanut Rainbow puhdistusjärjestelmän
                maahantuojana
              </Heading>
              <Text align="center" fontSize="xl" maxWidth={600}>
                Kiitos kaikille asiakkaillemme viimeisestä 17 vuodesta!
              </Text>
              <Text align="center" fontSize="xl" maxWidth={600}>
                Thermo Care Oy on siirtynyt toisen vesipuhdistusjärjestelmän
                maahantuontiin ja myyntiin.
              </Text>
              <Text align="center" fontSize="xl" maxWidth={600}>
                Emme kuitenkaan jätä sinua pulaan eli mikäli tarvitset apua tai
                huoltoa laitteesi kanssa, niin ota rohkeasti yhteyttä.
              </Text>
              <Text align="center" fontSize="xl" maxWidth={600}>
                Löydät meidät osoitteesta:
              </Text>
              <Text align="center" fontSize="xl" maxWidth={600}>
                Thermo Care Oy
                <br />
                Pienen Neulamäentie 2 LT 4<br />
                70800 Kuopio
              </Text>
              <Text align="center" fontSize="xl" maxWidth={600}>
                Voit kysyä lisätietoa numerosta{" "}
                <a href="tel:+358505663765">050 566 3765</a>.
              </Text>
              <Text align="center" fontSize="xl" maxWidth={600}>
                Terveisin Terttu
              </Text>
            </VStack>
          </Flex>
        </div>
      </Grid>
    </Box>
  </>
)

export default IndexPage
